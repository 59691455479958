import React from "react";
import { Button, Col, Container, Row, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DeclinedImg from "../../assets/declined_caution.svg";
// import useConfigStore from "../../stores/useConfigStore/useConfigStore";
import useLocationStore from "../../stores/useLocationIdStore/useLocationIdStore";
import useBootStore from "../../stores/useBootStore/useBootStore";
import CustomerSupport from "../../components/CustomerSupport/CustomerSupport";

function Declined() {
  // const { config } = useConfigStore();
  const bootStore = useBootStore.getState().boot;
  const { welldReturnToUrl } = useLocationStore();

  const navigate = useNavigate();

  // const fallback =
  //   config.find((v) => v.key === "eligibility.fallbackUrl")?.value ?? "";

  const handleFallback = () => {
    window.location.replace(
      `${bootStore?.welldBaseReturnURL}${welldReturnToUrl}?isTivityRedirect=true`
    );

    // if (fallback !== "") {
    //   window.location.replace(`${fallback}?status=NOT_FOUND`);
    // }
  };

  return (
    <Container className="declined-form-container-multiple-details shadow border-0">
      <div className="d-flex justify-content-center">
        <img
          src={DeclinedImg}
          alt="Declined"
          width="70px"
          style={{ marginBottom: "20px" }}
        />
      </div>
      <Stack className="align-items-left" gap={3}>
        <h2 className="text-center">
          <b>
            We couldn&apos;t find you,
            <br /> but you still might be eligible!
          </b>
        </h2>
        <h6 className="text-align-left mb-0">
          <b>Why your eligibility check might fail</b>
        </h6>
        <ul>
          <li>
            The name you provided is different than the one your health plan or
            employer has on file
          </li>
          <li>
            The zip code you provided is different than the one your health plan
            or employer has on file
          </li>
          <li>
            You have a new health plan, or are new to your employer but the
            coverage hasn&apos;t started yet
          </li>
        </ul>
        <CustomerSupport />
        <Row className="justify-content-around">
          <Col xs={12} md className="mb-2">
            <Button
              variant="outline-primary"
              className="form-button"
              onClick={() => navigate("/eligibility")}
            >
              <b>Try Again</b>
            </Button>
          </Col>
          <Col xs={12} md>
            <Button
              variant="primary"
              className="form-button"
              style={{ whiteSpace: "nowrap" }}
              onClick={handleFallback}
            >
              <b>Return to Location Portal</b>
            </Button>
          </Col>
        </Row>
      </Stack>
    </Container>
  );
}

export default Declined;
