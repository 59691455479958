import React from "react";
import { Button, Container, Stack } from "react-bootstrap";
// import { Navigate } from "react-router-dom";
// import Congratulations from "../../assets/confetti_illustration.svg";
import CheckMark from "../../assets/check_mark.svg";
import useLocationStore from "../../stores/useLocationIdStore/useLocationIdStore";
import useTokenStore from "../../stores/useTokenStore/useTokenStore";
import useAlertStore from "../../stores/useAlertStore/useAlertStore";
import ALERTS from "../../util/Alerts";
import useBootStore from "../../stores/useBootStore/useBootStore";
import CountDown from "../../components/CountDown/CountDown";
// import usePartnerName from "../../hooks/usePartnerName/usePartnerName";

function Success() {
  // const name = usePartnerName();
  const bootStore = useBootStore.getState().boot;
  const { token } = useTokenStore();
  const { setAlert } = useAlertStore();
  const { welldReturnToUrl } = useLocationStore();

  const handleComplete = () => {
    window.location.replace(
      `${bootStore?.welldBaseReturnURL}${welldReturnToUrl}?isTivityRedirect=true&token=${token}`
    );

    if (token) {
      window.location.replace(
        `${bootStore?.welldBaseReturnURL}${welldReturnToUrl}?isTivityRedirect=true&token=${token}`
      );
    } else {
      setAlert(ALERTS.ERROR);
    }
  };

  // if (!token) {
  //   return <Navigate to="/Welldpage" />;
  // }

  return (
    <Container className="success-form-container-multiple-details shadow border-0">
      <div className="d-flex justify-content-center">
        <img
          src={CheckMark}
          alt="congratulations"
          style={{ marginBottom: "40px" }}
        />
      </div>
      <Stack className="align-items-center ps-4" gap={3}>
        <h2 className="text-center">
          <b>Congratulations! You&apos;re eligible!</b>
        </h2>
        {/* <p className="text-center">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.
        </p> */}
        <p className="text-left">
          You will automatically return to Welld shortly.
        </p>
        <CountDown seconds={8} timeUp={handleComplete} />
        <div>
          <Button
            variant="primary"
            className="form-button"
            style={{ maxWidth: "300px" }}
            onClick={handleComplete}
          >
            <b>Return to Location Portal</b>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-chevron-right"
              viewBox="0 0 16 16"
              style={{ marginBottom: "4px" }}
            >
              <path
                fillRule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </Button>
        </div>
      </Stack>
    </Container>
  );
}

export default Success;
